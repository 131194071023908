@import 'src/assets/styles/basic';

.sponsoring-container {
  max-width: 900px;

  .row {
    padding-bottom: 1em;
    padding-top: 1em;
  }

  h2 {
    padding-bottom: .5em;
  }
}

.sponsored-seats {
  border-collapse: collapse;
  width: 100%;

  td {
    background-color: $table-cell;
    border: 1px solid $grey;
    padding: .5em 1em;
    text-align: right;
  }

  th {
    background-color: $table-header;
    border: 1px solid $grey;
    font-family: $main-serif;
    font-weight: bolder;
    padding: .5em 1em;
  }
}


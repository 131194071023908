@charset "UTF-8";
@import 'colors';
@import 'fonts';

a{
  text-decoration: none;
}

.page-header {
  border-bottom: 1px solid $separator;
  margin: 44px 0 22px;
  padding-bottom: 10px;
}

.extraspace {
  margin-bottom: 50px;
}

.segment-header {
  border-bottom: 1px solid transparent;
  margin: 44px 0 22px;
  padding-bottom: 10px;
}

.row {
  @media(max-width: 767px) {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}


@import '../../../assets/styles/_colors';

#socrates-description {

    .bullet-points ul:first-child {
        font-size: 2em;
    }

    @media(max-width: 767px) {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
@import 'assets/styles/_basic';
@import '~bootstrap/scss/bootstrap';

.App {
    text-align: left;
}

.schedule {
  th {
    font-weight: 600;
    font-size: 1.5em;
  }
  tr {
    height: 2em;
  }
  td {
    vertical-align: top;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
  .break {
    background-color: #d35f5f;
    color: #d3d3d3;
    td.session {
      text-align: center;
    }
  }
  td.time {
    text-align: right;
  }
  .authors{
    font-size: smaller;
  }
  .title{
    margin-bottom: .2em;
    color: #062227;
    text-decoration: underline;
  }
}

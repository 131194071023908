@import 'src/assets/styles/basic';

#conferences {
  .conferences {
    background-color: $conferences-background;
    color: $conferences-text;

    h4 {
      color: $conferences-text;
    }
  }

  .conference {
    line-height: 1.3em;

    a {
      font-size: .9em;
    }

    small {
      font-size: .7em;
    }
  }
}

#newsletter {
  .pulse {
    -webkit-animation: pulse 1.5s ease-out;
    -webkit-animation-iteration-count: 2;
  }

  @-webkit-keyframes pulse {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: coral;
    }
    100% {
      background-color: inherit;
    }
  }

  @media(max-width: 767px) {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.input-group-prepend{
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: 6px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  .input-group-text {
    background-color: transparent;
    border: 0;
  }
}
@charset "UTF-8";

.nav-link {
  margin-left: .5em;
  margin-right: .5em;
}

.nav-link-text {
  font-weight: normal;
  padding-left: .125em;
}

@media screen and (min-width: 1200px) {
  .nav-link-icon {
    display: none;
  }
}

@media screen and (min-width: 1350px) {
  .nav-link-icon {
    display: inline;
  }
}

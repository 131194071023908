@import '../../assets/styles/colors';

.newsletter-sign-out {
  .pulse {
    -webkit-animation: pulse 1.5s ease-out;
    -webkit-animation-iteration-count: 2;
  }

  @-webkit-keyframes pulse {
    0% {
      background-color: inherit;
    }

    50% {
      background-color: $coral;
    }

    100% {
      background-color: inherit;
    }
  }

  @media(max-width: 767px) {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}


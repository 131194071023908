@charset "UTF-8";
@import 'src/assets/styles/basic';

.application {
  .container {
    background: $form-background;
    border: 1px solid $border-secondary;
    border-radius: .25rem;
    margin-bottom: 2em;
    margin-top: 2em;
    padding: 1em;

    &.form {
      padding: 2em;
    }
  }

  .announcement-container {
    background: $announcement-background;
    border: 1px solid $border-secondary;
    border-radius: .25rem;
    margin-bottom: 6em;
    margin-top: 6em;
    padding: 2em;
  }

  .announcement-paragraph {
    padding-bottom: 1em;
  }

  .page-header {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .diversity-info,
  .form-info {
    font-size: .8em;

    ul {
      font-size: 1.2em;
    }
  }
  .diversity-financial-aid{
    margin-top: 1em;

     .financialAidHelp{
      font-size: .8em;
      padding-top: 2px;
      padding-bottom: 2px;
     }
  }

  .col-form-label {
    margin: 0 -5px;
  }

  .btn-primary {
    font-size: 1.5em;
    margin-top: .5em;

    @media (max-width: 767px) {
      font-size: 1.2em;
    }
  }

  .registration-accordion {
    margin-bottom: 2em;
    margin-top: 1.5em;
  }

  button {
    font-size: .87em;

    &.btn-link {
      color: $header-button-dark;
      cursor: pointer;
      font-size: .87em;
      font-weight: bold;
      text-decoration: none;

      &:hover,
      &[aria-expanded^="true"] {
        color: $header-button-dark-hover;
        font-weight: bold;
      }
    }
  }

  .pricing {
    border-collapse: collapse;

    th {
      background-color: $table-header;
      border: 2px solid $pricing-border;
      border-collapse: collapse;
      padding: .5em;
    }

    td {
      background-color: $table-cell;
      border: 2px solid $pricing-border;
      border-collapse: collapse;
      padding: .5em;
    }
  }

  .pricing-example {
    td {
      padding-left: 1em;
      padding-right: 1em;
    }

    .total {
      border-top: 2px solid $border-primary;
    }

    .total-light {
      color: $pricing-table-background;
    }
  }

  .roommate {
    border: 1px solid $border-secondary;
    border-radius: .25rem;
  }
}

.alert {
  border-radius: .5rem;
  border-width: 2px;
}

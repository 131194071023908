@charset "UTF-8";
@import 'src/assets/styles/basic';

#history {
  .card {
    margin-bottom: 1.5em;
  }

  .year-2011 {
    color: $darkred;
  }

  .year-2012 {
    color: $darkgreen;
  }

  .year-2013 {
    color: $blue;
  }

  .year-2014 {
    color: $grey;
  }

  .year-2015 {
    color: $lightpurple;
  }

  .year-2016 {
    color: $pink;
  }

  .year-2017 {
    color: $darkcyan;
  }

  .year-2018 {
    color: $red;
  }

  .year-2019 {
    color: $rebeccapurple;
  }

  .year-2020 {
    color: $grey;
  }

  .year-2021 {
    color: $orangered;
  }

  .blog-article-list {
    font-size: 1em;
    margin-top: .5em;
  }
}

@charset "UTF-8";
@import 'src/assets/styles/basic';

summary {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

.summary-class {
  margin-top: 5px;
  font-weight: bold;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px; 
}

.content-class {
  padding: 10px;
  font-size: 14px;
  color: #666;
}
@import 'colors';

$main-serif: "PT Serif Caption", "Times New Roman", serif;


/* pt-sans-caption-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/pt-sans-caption-v19-latin-regular.woff2') format('woff2');
}

/* pt-sans-caption-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/pt-sans-caption-v19-latin-700.woff2') format('woff2');
}



@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

h1 {
  color: inherit;
  font-family: $main-serif;
  font-size: 5.5em;
  font-weight: 700;
  padding-top: .2em;
  text-align: center;

  @media(max-width: 767px) {
    font-size: 3em;
  }
}

h2 {
  color: inherit;
  font-family: $main-serif;
  font-size: 2em;
  font-weight: 400;
  text-align: center;

  @media(max-width: 767px) {
    font-size: 1.5em;
  }
}

h3 {
  color: inherit;
  font-family: $main-serif;
  font-size: 2em;
  font-weight: 400;
  text-align: center;

  @media(max-width: 767px) {
    font-size: 1.5em;
  }
}

h4 {
  color: inherit;
  font-family: $main-serif;
  font-size: 1.5em;
  font-weight: 400;
  text-align: left;

  @media(max-width: 767px) {
    font-size: 1.2em;
  }
}

h5 {
  color: inherit;
  font-family: $main-serif;
  font-size: 1.2em;
  font-style: italic;
  font-weight: 400;
  text-align: left;

  @media(max-width: 767px) {
    font-size: 1.2em;
  }
}

p {
  font-family: $main-serif;
  font-size: 1.2em;
  font-weight: normal;

  @media(max-width: 767px) {
    font-size: 1em;
  }
}

ul {
  font-family: $main-serif;
  font-size: 1.2rem;
  font-weight: normal;
  margin-top: 1.5em;

  @media(max-width: 767px) {
    font-size: 1em;
  }

  &.navbar-nav {
    margin-top: 0;
  }
}

li {
  margin-top: .5em;

  &.nav-item {
    margin-top: 0;
  }
}

dt {
  font-family: $main-serif;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1.5em;

  @media(max-width: 767px) {
    font-size: 1em;
  }
}

dd {
  font-family: $main-serif;
  font-size: 1rem;
  font-weight: normal;
  margin-top: .5em;

  @media(max-width: 767px) {
    font-size: .8em;
  }
}

.btn,
.form-text,
label,
td {
  font-family: $main-serif;
  font-weight: normal;
}


th {
  font-family: $main-serif;
  font-weight: bold;
}

.numeric {
  text-align: right;
}

input {
  @include placeholder {
    color: $placeholder !important;
  }
}

.schedule {
  margin-top: 40px;
  margin-bottom: 20px;

  .heading {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;

    a {
      text-decoration: inherit;
      color: inherit;
    }
    .heading-anchor {
      padding-right: 5px;
      opacity: 0.5;
    }
  }

  .session-card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .welcome-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-column: 1 / -1;
      background-color: #b8e3af;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      font-weight: bold;
    }

    .welcome-location {
      font-size: 0.9em;
      color: #777;
      margin-bottom: 5px;
    }

    .name {
      align-self: center;
    }

    .time {
      grid-column: 1 / -1;

      span {
        background-color: #f0f0f0;
        padding: 5px;
        border-radius: 5px;
      }
    }

    .track {
      padding: 10px;
      border-radius: 5px;

      .name {
        font-weight: bold;
        text-decoration: underline;
      }

      .track-number {
        font-size: 0.9em;
        color: #777;
        margin-bottom: 5px;
      }

      .cancelled {
        margin-top: 10px;
        color: #ff0000;
        font-size: 1.2em;
        text-align: center;
      }
    }

    .lightblue {
      background-color: #e8f5ff;
    }

    .paleteal {
      background-color: #f2faf2;
    }
  }

  .legend {
    display: flex;
    justify-content: flex-start;
    max-width: 500px;
    font-size: smaller;

    .lightblue {
      background-color: #e8f5ff;
      padding: 5px;
      border-radius: 5px;
      margin-right: 10px;
    }

    .paleteal {
      background-color: #f2faf2;
      padding: 5px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
}

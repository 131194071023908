@import 'src/assets/styles/basic';

#sponsoring {

  .hidden-lg {
    display: none;
  }

  .logocolumn {
    background-color: $sponsors-background;

    img {
      width: 160px;
    }
  }

  .sidebar {
    text-align: right;

    @media(max-width: 767px) {
      text-align: center;
    }

    .btn {
      margin-bottom: 15px;
      width: 160px;
    }
  }

  .sponsor-empty {
    color: $header-light;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
}

@charset "UTF-8";

#header {
  background-size: cover;
  background: transparent url(../../assets/img/stage.jpg) no-repeat top center;
  margin-bottom: 0;

  .socrates-header {
    display: block;
    text-align: center;
    color: black;
    padding: 0 2em;

    @media(max-width: 767px) {
      padding: 0;
    }
  }

  #logo {
    width: 263px;
    height: 260px;
    text-align: center;
    vertical-align: middle;
    margin: 0 2em 0 2em;

    @media(max-width: 767px) {
      margin: 0;
    }
  }
  .btn-primary {
    font-size:1.5em;
    margin-top: 2em;
    text-align: center;
  }
  & h4 {
    text-align: center;
  }
}

.dropdown .nav-link {
  font-family: "PT Serif Caption", "Times New Roman", "serif";
  line-height: 1em;
}

.navbar-brand {
  font-family: "PT Serif Caption", "Times New Roman", "serif";
  line-height: 2.5em;

  & img {
    margin-top:-.2em;
  }
}

.nav-item .btn-application {
   margin-left: 1em;
   margin-top: .2em;
}


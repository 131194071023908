// Common
$separator: #eee;
$placeholder: #aaa;

// Applications
$border-primary: #000;
$border-secondary: #6c757d;
$form-background: rgba(0, 0, 0, .03);
$announcement-background: rgba(255, 247, 188, 1);
$pricing-table-background: #808080;

// Header
$header-light: #666;
$header-dark: #000;
$header-button-dark: #666;
$header-button-dark-hover: #000;

// Footer
$footer-border: #ccc;

// Sponsors
$sponsors-background: #f9f9f9;

// Conferences
$conferences-background: #fff;
$conferences-text: #666;

// Application
$pricing-border: #fff;

// Tables
$table-header: #ddd;
$table-cell: #efefef;

//Named colors
// Dark shades
$darkred: #8b0000;
$darkgreen: #006400;
$darkcyan: #008b8b;
$dark: #343a40;
$gray-dark: #343a40;

// Light shades
$lightpurple: #5d4cdc;
$light: #f8f9fa;

// Primary colors
$blue: #00f;
$red: #f00;
$green: #28a745;

// Secondary colors
$indigo: #6610f2;
$orange: #fd7e14;
$yellow: #ffc107;
$teal: #20c997;
$cyan: #17a2b8;

// Grays
$gray: #6c757d;
$grey: #808080;

// Others
$coral: #ff7f50;
$rebeccapurple: #639;
$orangered: #ff4500;
$pink: #b57b9a;
$white: #fff;

// Bootstrap color scheme
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;